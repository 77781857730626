import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionSharedComponent } from './extensions/5a8f9155e2b89f437547d6164cf12629305c5f623b498d0034c43a59c9b295f9/subscription-ui-shared.module';
import { ContactExtensionModule } from './extensions/1bfb9b03db3af8d36c2e88141f6f2655b3c2b30942a5d600b4306e00f5148233/contact-ui-extension.module';



@NgModule({
    imports: [CommonModule, SubscriptionSharedComponent, ContactExtensionModule],
    providers: [],
})
export class SharedExtensionsModule {}
