import { NgModule } from '@angular/core';
import { SharedModule, addNavMenuSection } from '@vendure/admin-ui/core';

@NgModule({
    imports: [SharedModule],
    providers: [
        addNavMenuSection(
            {
                id: 'subscription-nav',
                label: 'Subscription',
                items: [
                    {
                        id: 'subscription-packages',
                        label: 'Packages',
                        routerLink: ['/extensions/subscription/packages'],
                        icon: 'folder-open',
                    },
                    {
                        id: 'subscriptions',
                        label: 'Subscriptions',
                        routerLink: ['/extensions/subscription/subscriptions'],
                        icon: 'wallet',
                    },
                ],
            },
            // Add this section before the "settings" section
            'settings',
        ),
    ],
})
export class SubscriptionSharedComponent {}
